import {Attribute} from './attribute';
import {RoomGroup} from './room-group';
import {Image} from './image';

export class Package {
  id: number;
  name: string;
  description?: string;
  present_from: Date;
  present_to: Date;
  available_from: Date;
  available_to: Date;
  price_from: number;
  strict_dates: boolean;
  minimum_days: number;
  images: Image[];
  attributes: Attribute[];
  package_room_group: RoomGroupPackage[];
  created_at?: string;
  updated_at?: string;
  availability_status: number;

  constructor() {
    this.id = this.name = this.description = this.present_from = this.present_to = this.price_from = this.available_from = this.available_to = this.strict_dates = this.minimum_days = this.created_at = this.updated_at = null;
    this.package_room_group = this.images = this.attributes = [];
    this.availability_status = 0;
  }
}

export class RoomGroupPackage {
  price: number;
  price_for_additional_person: number;
  advance_price: number;
  room_group: RoomGroup[];

  constructor() {
    this.price = this.price_for_additional_person = null;
    this.room_group = [];
  }
}
