import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit {

  @Input() packages: boolean;

  constructor() { }

  ngOnInit() {
  }

}
