import { Component, Injector } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget-component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent extends BaseWidgetComponent {

  constructor(injector: Injector) {
    super(injector);
  }

}
