import {Component, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxListComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => CheckboxListComponent), multi: true },
  ],
  encapsulation: ViewEncapsulation.None
})
export class CheckboxListComponent implements ControlValueAccessor, Validator {

  @Input('items') items: any[];
  @Input('val') val: string;
  @Input('content') content: string;

  private selected = [];
  private invalid: boolean;

  private propagateChange = (_: any) => { };
  private propagateTouched = () => { };
  private propagateValidatorChange = () => { };

  onChange(event: MatCheckboxChange) {
    const val = event.source.value;

    if (event.checked && !this.selected.includes(val)) {
      this.selected.push(val);
    } else if (!event.checked) {
      this.selected.splice(this.selected.indexOf(val), 1);
    }

    this.propagateChange(this.selected);
    this.setValidity();
    this.propagateTouched();
    this.propagateValidatorChange();
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.selected = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // No need for implementation
  }

  validate(control: AbstractControl) {
    return (this.invalid) ? null : {
      checkboxListInvalid: {
        valid: false,
      },
    };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.propagateValidatorChange = fn;
  }

  private setValidity() {
    const requiredItems = this.items.filter(item => item.required).map(item => item[this.val]) || [];
    const selected = this.selected || [];

    this.invalid = requiredItems.every(item => selected.includes(item));
  }

}
