import { Hotel } from './hotel';
import { Role } from './role';
import { MenuEntry } from './menu-entry';

export class User {
  first_name: string;
  last_name: string;
  phone: number | null = null;
  email: string = null;
  password: string;
  password_confirmation: string;
  hotels: Hotel[];
  hotels_count: number;
  roles: Role[];
  menu?: MenuEntry[];

  constructor() {
    this.first_name = this.last_name = this.phone = this.email = this.password = this.password_confirmation = this.hotels_count = null;
    this.hotels = [];
    this.roles = [];
    this.menu = [];
  }

  hasRole(roleName: string): boolean {
    return this.roles.some(role => role.name === roleName);
  }

  onlyRole(roleName: string): boolean {
    return this.roles.every(role => role.name === roleName);
  }
}
