import { Component, Injector } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget-component';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent extends BaseWidgetComponent {

  constructor(injector: Injector) {
    super(injector);
  }

}
