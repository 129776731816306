import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import { fade, toggleFade } from '../../../animations';
import { Hotel } from '../../../../shared/models/hotel';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    fade,
    toggleFade
  ]
})
export class HeaderComponent implements OnInit {
  public menuLang = false;
  public menuCurrnecy = false;

  @Input('hotel') hotel: Hotel;

  constructor() {
  }

  ngOnInit() {}

}
