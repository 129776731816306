import { CheckboxListComponent } from './components/checkbox-list/checkbox-list.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetRoutingModule } from './widget-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSiemaModule } from 'ngx-siema';
import { BsDatepickerModule, defineLocale, plLocale } from 'ngx-bootstrap';

import { RoomsComponent } from './components/rooms/rooms.component';
import { HotelComponent } from './components/hotel/hotel.component';
import { FilterComponent } from './components/filter/filter.component';
import { PackagesComponent } from './components/packages/packages.component';
import { CheckoutComponent } from './components/checkout/checkout.component';

import { RoomComponent } from './components/room/room.component';
import { SummaryComponent } from './components/summary/summary.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { HeaderMinimalComponent } from './components/layout/header-minimal/header-minimal.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { WidgetService } from './services/widget.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { StartComponent } from './components/start/start.component';
import { WidgetInterceptor } from './interceptors/widget.interceptor';
import { HotelHashInterceptor } from './interceptors/hotel-hash.interceptor';
import { ErrorComponent } from './components/error/error.component';
import { MaterialModule } from '../shared/modules/material.module';
import { PaymentComponent } from './components/payment/payment.component';
import { PopupGalleryComponent } from './components/popup-gallery/popup-gallery.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { SetStylesComponent } from './components/set-styles/set-styles.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MapComponent } from './components/map/map.component';
import { FilterOfferComponent } from './components/filter-offer/filter-offer.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { PackageComponent } from './components/package/package.component';

defineLocale('pl', plLocale);

@NgModule({
  declarations: [
    StartComponent,
    ErrorComponent,

    RoomsComponent,
    HotelComponent,
    FilterComponent,
    FilterOfferComponent,
    PackagesComponent,
    CheckoutComponent,
    ConfirmationComponent,
    PaymentComponent,
    ThankYouComponent,
    CheckboxListComponent,

    HeaderComponent,
    HeaderMinimalComponent,
    FooterComponent,
    RoomComponent,
    SummaryComponent,
    PopupGalleryComponent,
    PrivacyPolicyComponent,
    SetStylesComponent,
    LoaderComponent,
    MapComponent,
    SwitcherComponent,
    PackageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgxSiemaModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: WidgetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HotelHashInterceptor, multi: true },
    WidgetService,
  ]
})
export class WidgetModule { }
