import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { WidgetService } from '../services/widget.service';

@Injectable()
export class HotelHashInterceptor implements HttpInterceptor {

  private widgetService: WidgetService;

  constructor(
    private injector: Injector
  ) {
    this.widgetService = this.injector.get(WidgetService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = req.headers;
    const hash = this.widgetService.hash();

    if (hash) {
      headers = headers.set('Hash', hash);

      const hashedRequest = req.clone({
        headers
      });

      return next.handle(hashedRequest);
    }

    return next.handle(req.clone({
      headers
    }));
  }
}
