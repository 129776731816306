import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {GmapService} from '../../../shared/services/gmap.service';
import {WidgetService} from '../../services/widget.service';
import {Hotel} from '../../../shared/models/hotel';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [
    GmapService
  ]
})
export class MapComponent implements OnInit {

  @Output('close') close = new EventEmitter<void>();
  @ViewChild('gmap', { static: false }) set gmap(content: ElementRef) {
    if (content) {
      this.gmapService.createMap(content, this.hotel.address.position);
    }
  }

  hotel: Hotel;

  constructor(
    private widgetService: WidgetService,
    private gmapService: GmapService
  ) { }

  ngOnInit() {
    this.hotel = this.widgetService.getHotel();
  }

  onClose() {
    this.close.emit();
  }

}
