export class Address {
    id: number;
    postal_code: string;
    city: string;
    street: string;
    building_number: number;
    flat_number: number;
    created_at: string;
    updated_at: string;
    position?: google.maps.LatLng;

    constructor() {
      this.id = this.postal_code = this.city = this.street = this.building_number = this.flat_number = this.created_at = this.updated_at = null;
    }
}
