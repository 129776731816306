import { APP_CONFIG, AppConfig } from './app.config';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(
    private renderer: Renderer2,
    @Inject(APP_CONFIG) private config: AppConfig,
    @Inject(DOCUMENT) private document: Document
  ) {
    const element = this.renderer.createElement('script');
    element.src = `https://maps.googleapis.com/maps/api/js?key=${this.config.gmaps_key}`;

    this.renderer.appendChild(this.document.body, element);
  }
}
