import { Agreement } from './agreement';
import { Address } from './address';
import { Attribute } from './attribute';
import { Image } from './image';
import { Configuration } from './configuration';
import { RoomGroupStandard } from './room-group-standard';
import { RoomGroupType } from './room-group-type';

export class Hotel {
  id: number;
  hash: string;
  name: string;
  caption: string;
  address: Address;
  images?: Image[];
  attributes?: Attribute[];
  description: string;
  check_in_at: string;
  check_out_at: string;
  configuration: Configuration;
  agreements: Agreement[];
  room_group_standard: RoomGroupStandard[];
  room_group_type: RoomGroupType[];
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = this.name = this.caption = this.description = this.check_in_at = this.check_out_at = this.created_at = this.updated_at = null;
    this.address = new Address();
    this.attributes = [];
    this.images = [];
    this.configuration = new Configuration();
  }
}
