import {PriceTable} from './price-table';
import {Hotel} from './hotel';
import {RoomGroupStandard} from './room-group-standard';
import {RoomGroupType} from './room-group-type';
import {Room} from './room';
import {Image} from './image';
import {Attribute} from './attribute';
import {Calendar} from './calendar';

export class RoomGroup {
  id: number;
  name: string;
  price: number;
  advance_price?: number;
  advance_payment_type: number;
  advance_payment_value: number;
  price_for_additional_person: number;
  description: string;
  hotel: Hotel;
  calendar: string;
  imported_calendars: Calendar[];
  room_group_standard: RoomGroupStandard;
  room_group_type: RoomGroupType;
  price_table: PriceTable;
  beds: number;
  basic_beds: number;
  rooms: Room[];
  images: Image[];
  attributes: Attribute[];
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = this.name = this.description = this.calendar = this.advance_price = this.price = this.advance_payment_value = this.price_for_additional_person = this.beds = this.basic_beds = this.created_at = this.updated_at = null;
    this.rooms = [];
    this.attributes = [];
    this.images = [];
    this.hotel = new Hotel();
    this.room_group_standard = new RoomGroupStandard();
    this.room_group_type = new RoomGroupType();
    this.price_table = new PriceTable();
    this.advance_payment_type = 100;
  }
}
