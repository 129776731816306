import { API_URL } from './_dev';

export const env = {
  production: false,
  api: API_URL,
  payload_properties: ['exp', 'iat', 'iss', 'jti', 'nbf', 'prv', 'sub'],
  payload_issuers: [`${API_URL}/login`],
  chunkUpload: `${API_URL}/images`,
  gmaps_key: 'AIzaSyDOMmQB6ntH5n-6d9JTG-EtzQH_1EoMS7o',
};
