import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RoomsComponent} from './components/rooms/rooms.component';
import {PackagesComponent} from './components/packages/packages.component';
import {ThankYouComponent} from './components/thank-you/thank-you.component';
import {StartComponent} from './components/start/start.component';
import {ValidHashGuard} from './guards/valid-hash.guard';
import {ErrorComponent} from './components/error/error.component';
import {ConfirmationComponent} from './components/confirmation/confirmation.component';
import {PaymentComponent} from './components/payment/payment.component';

const routes: Routes = [
  {path: 'start', component: StartComponent},
  {path: 'error', component: ErrorComponent},
  {path: '', pathMatch: 'full', component: RoomsComponent, canActivate: [ValidHashGuard]},
  {path: 'packages', component: PackagesComponent, canActivate: [ValidHashGuard]},
  {path: 'confirmation', component: ConfirmationComponent, canActivate: [ValidHashGuard]},
  {path: 'payment', component: PaymentComponent, canActivate: [ValidHashGuard]},
  {path: 'thank-you', component: ThankYouComponent, canActivate: [ValidHashGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WidgetRoutingModule {
}
