import * as moment from 'moment';

export function _indexOf(array: any[], element: any, property: string) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][property].toString().toLowerCase() === element[property].toString().toLowerCase()) {
      return i;
    }
  }

  return -1;
}

export function dateToString(date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const monthIndex = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${year}-${monthIndex}-${day}`;
}

export function monthsDiff(startDate: Date, endDate: Date) {
  const startMonth = startDate.getMonth() + 1;
  const startYear = startDate.getFullYear();

  const endMonth = endDate.getMonth() + 1;
  const endYear = endDate.getFullYear();

  if (startYear === endYear && startMonth === endMonth) {
    return 1;
  }

  const fullYearDiff = endYear - startYear - 1;

  let months = fullYearDiff > 0 ? fullYearDiff * 12 : 0;

  if (startYear === endYear) {
    months += endMonth - startMonth + 1;
  } else {
    months += 13 - startMonth + endMonth;
  }


  return months;
}
