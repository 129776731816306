import { MatPaginatorIntl } from '@angular/material';

export class MatPaginatorLang extends MatPaginatorIntl {
  firstPageLabel = 'Pierwsza strona';
  lastPageLabel = 'Ostatnia strona';
  nextPageLabel = 'Następna strona';
  previousPageLabel = 'Poprzednia strona';
  itemsPerPageLabel = 'Elementów na stronę';

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return '0 z ' + length;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' z ' + length;
  }
}
