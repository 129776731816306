import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { DynamicLocalStorage, LocalStorage } from './local-storage.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { EnumService } from './enum.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginAsService {
  public loginAsObserver: Subject<boolean> = new Subject();
  public isLogin: boolean;
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private localStorage: LocalStorage,
    private http: HttpClient,
    private dynamicLocalStorage: DynamicLocalStorage<boolean>,
    private router: Router,
    public enumService: EnumService
  ) {
  }

  loginAs(id: number) {
    return this.http.post<any>(`${this.config.api}/login-as`, {id: id}).subscribe(auth => {
      this.localStorage.setItem('user', auth.user);
      this.localStorage.setItem('login_as_token', auth.access_token);
      this.localStorage.setItem('login_as_token_type', auth.token_type);
      this.localStorage.setItem('login_as_expires_at', auth.expires_at);
      this.localStorage.removeItem('bse_hotel');
      this.localStorage.removeItem('bse_hash');
      this.loginAsObserver.next(true);
      this.dynamicLocalStorage.setItem('isLogin', true);
      this.enumService.clearEnums();

      this.router.navigate(['/']);
    });
  }

  get token() {
    return this.localStorage.getItem('login_as_token');
  }

  get expiresAt() {
    return this.localStorage.getItem('login_as_expires_at');
  }

  get logged(): boolean {
    return this.dynamicLocalStorage.getItem('isLogin');
  }

  logout() {
    this.localStorage.removeItem('user');
    this.localStorage.removeItem('login_as_token');
    this.localStorage.removeItem('login_as_token_type');
    this.localStorage.removeItem('login_as_expires_at');
    this.localStorage.removeItem('bse_hotel');
    this.localStorage.removeItem('bse_hash');
    this.dynamicLocalStorage.setItem('isLogin', false);
    this.enumService.clearEnums();

    this.loginAsObserver.next(true);
  }

  isValid() {
    const token = this.token;
    const expiresAt = this.expiresAt;

    if (!token || !expiresAt) {
      return false;
    }

    return this.validateToken(token, parseInt(expiresAt, 10));
  }

  protected validateToken(token: string, expiresAt: number) {
    if (Math.floor(Date.now() / 1000) > expiresAt) {
      return false;
    }

    const payload = JSON.parse(atob(token.split('.')[1]));
    const validPayload = this.config.payload_properties.every(key => key in payload);

    if (!validPayload) {
      return false;
    }

    if (Object.values(this.config.payload_issuers).includes(payload.iss)) {
      return false;
    }

    return true;
  }
}
