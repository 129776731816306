import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { NgxSiemaService, NgxSiemaOptions } from 'ngx-siema';
import { RoomGroup } from '../../../shared/models/room-group';
import { Hotel } from '../../../shared/models/hotel';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss'],
})
export class RoomComponent implements OnInit {
  @Input('room') room: RoomGroup;
  @Output('close') close = new EventEmitter<void>();

  hotel: Hotel;

  options: NgxSiemaOptions = {
    selector: '.bse-hotel-imgs',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: true
  };

  constructor(
    public ngxSiemaService: NgxSiemaService,
    private widgetService: WidgetService,
    // private gmapService: GmapService
  ) { }

  ngOnInit() {
    this.hotel = this.widgetService.getHotel();
  }

  onClose() {
    this.close.emit();
  }
}
