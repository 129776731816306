import {trigger, state, style, transition, animate} from '@angular/animations';

export let fade = trigger('fade', [
  state('void', style({'opacity': 0})),
  transition(':enter, :leave', [
    animate(200)
  ])
]);

export let fadeIn = trigger('fadeIn', [
  state('void', style({'opacity': 0})),
  transition(':enter', [
    animate(600)
  ])
]);

export let toggle = trigger('toggle', [
  state('void', style({'height': 0, 'overflow': 'hidden'})),
  transition(':enter, :leave', [
    animate(200)
  ])
]);

export let toggleFade = trigger('toggleFade', [
  state('void', style({'opacity': 0, 'height': 0})),
  transition(':enter, :leave', [
    animate(200)
  ])
]);
