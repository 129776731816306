import { Subject } from 'rxjs';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { Injectable, Inject, PLATFORM_ID, Optional, isDevMode } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { MenuEntry } from '../models/menu-entry';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  isBrowser: boolean;
  menu: MenuEntry[] = [];
  private menuChanges = new Subject<void>();
  menuChanges$ = this.menuChanges.asObservable();

  constructor(
    private titleService: Title,
    private metaService: Meta,
    protected http: HttpClient,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject('serverUrl') public serverUrl: string,
    @Optional() @Inject('isProduction') public isProduction: boolean,
    @Inject(APP_CONFIG) protected config: AppConfig
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.serverUrl = this.isBrowser ? window.location.href : this.serverUrl;
    this.isProduction = this.isBrowser ? !isDevMode() : this.isProduction;
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  setKeywords(content: string) {
    const meta = {
      name: 'keywords',
      content
    };

    this.addOrUpdate(meta);
  }

  setDescription(content: string) {
    const meta = {
      name: 'description',
      content
    };

    this.addOrUpdate(meta);
  }

  disallowRobots() {
    const meta = {
      name: 'robots',
      content: 'noindex, nofollow'
    };

    this.addOrUpdate(meta);
  }

  setRobots(content: string) {
    const meta = {
      name: 'robots',
      content
    };

    this.addOrUpdate(meta);
  }

  addOrUpdate(meta: MetaDefinition) {
    if (!this.metaExists(meta.name)) {
      this.metaService.addTag(meta);
    } else {
      this.metaService.updateTag(meta);
    }
  }

  setCanonical(url: string) {
    let canonical = this.document.head.querySelector('link[rel="canonical"]');

    if (!canonical) {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.document.head.appendChild(link);

      canonical = link;
    }

    canonical.setAttribute('href', url);
  }

  metaExists(name: string) {
    return this.metaService.getTag(`name="${name}"`);
  }

  removeMetaTags(names: string[] = []) {
    names.forEach(name => this.metaService.removeTag(`name='${name}'`));
  }

  updateMenu(menu: MenuEntry[]) {
    this.menu = menu;

    this.menuChanges.next();
  }

}
