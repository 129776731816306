import { Hotel } from './hotel';

export class PriceTable {
  id: number;
  name: string;
  hotel: Hotel;
  price: number;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
  days: { [date: string]: number };

  constructor() {
    this.id = this.name = this.price = this.created_at = this.updated_at = this.start_date = this.end_date = null;
    this.hotel = new Hotel();
    this.days = {};
  }
}
