import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../../shared/services/message.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class WidgetInterceptor implements HttpInterceptor {

  private messageService: MessageService;

  constructor(private injector: Injector) {
    this.messageService = this.injector.get(MessageService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (response) => response,
        (error: HttpErrorResponse) => {
          switch (error.status) {
            case 400:
              break;
            case 422:
              break;
            default:
              error.error.message = 'Wystąpił nieoczekiwany błąd. Prosimy spróbować ponownie.';
              break;
          }

          this.messageService.info(error.error.message, 'Oops!');
        }
      )
    );
  }
}
