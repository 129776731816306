import { WidgetService } from '../services/widget.service';
import { Booking } from '../../shared/models/booking';
import { Hotel } from '../../shared/models/hotel';
import { OnInit, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';

export abstract class BaseWidgetComponent implements OnInit {

  hotel: Hotel;
  booking: Booking;

  protected widgetService: WidgetService;
  protected router: Router;
  protected messageService: MessageService;

  constructor(private injector: Injector) {
    this.widgetService = this.injector.get(WidgetService);
    this.router = this.injector.get(Router);
    this.messageService = this.injector.get(MessageService);
  }

  ngOnInit() {
    this.setDefaults();

    if (!this.hotel || !this.booking) {
      this.router.navigate(['/']);
    }
  }

  setDefaults() {
    this.hotel = this.widgetService.getHotel();
    this.booking = this.widgetService.getBooking();
  }
}
