export class RoomGroupType {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;

    constructor() {
      this.id = this.name = this.created_at = this.updated_at = null;
    }
}
