import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Hotel} from '../../../../shared/models/hotel';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input('hotel') hotel: Hotel;
  previewPrivacyPolicy = false;

  constructor() {
  }

  ngOnInit() {
  }

  togglePrivacyPolicy() {
    this.previewPrivacyPolicy = !this.previewPrivacyPolicy;
  }

  get currentYear() {
    return new Date();
  }
}
