import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {PreviewImageData} from '../../models/dialogs';

@Component({
  selector: 'app-preview-image',
  templateUrl: './preview-image.component.html',
  // TODO: style
  // styleUrls: ['./preview-image.component.scss']
})
export class PreviewImageComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PreviewImageData) {
  }

}
