import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '../../app.config';
import { DynamicLocalStorage, LocalStorage } from './local-storage.service';
import { UserLogin } from '../DTOs/user-login';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserRegister } from '../DTOs/user-register';
import { PasswordReset } from '../DTOs/password-reset';
import { Response } from '../models/response';
import { AuthLoginAsService } from './authLoginAs.service';
import { AppService } from './app.service';
import {Role} from '../models/role';

export const AuthEvent = {
  LOGGED_IN: 1,
  LOGGED_OUT: 2
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user: User = null;
  authEvents$ = new Subject<number>();


  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private localStorage: LocalStorage,
    private http: HttpClient,
    public authLoginAsService: AuthLoginAsService,
    public appService: AppService,
    public dynamicLocalStorage: DynamicLocalStorage<boolean>
  ) {
    this.getUser();
  }

  getUser() {
    if (this.user) {
      return Object.assign(new User(), this.user);
    }

    const user = this.localStorage.getItem('user');

    if (!user) {
      return null;
    }

    this.user = Object.assign(new User(), JSON.parse(decodeURIComponent((atob(user)))));

    // const tempUser = new User();
    // tempUser.fill(this.user);
    return this.user;
  }

  get token() {
    return this.localStorage.getItem('token');
  }

  get expiresAt() {
    return this.localStorage.getItem('expires_at');
  }

  isValid() {
    const token = this.token;
    const expiresAt = this.expiresAt;

    if (!token || !expiresAt) {
      return false;
    }

    return this.validateToken(token, parseInt(expiresAt, 10));
  }

  protected validateToken(token: string, expiresAt: number) {
    if (Math.floor(Date.now() / 1000) > expiresAt) {
      return false;
    }

    const payload = JSON.parse(atob(token.split('.')[1]));
    const validPayload = this.config.payload_properties.every(key => key in payload);

    if (!validPayload) {
      return false;
    }

    if (Object.values(this.config.payload_issuers).includes(payload.iss)) {
      return false;
    }

    return true;
  }

  hasRequiredRoles(userRoles: Role[], requiredRoles: string[]) {
    const roles = userRoles.map(role => role.name);
    return requiredRoles.every(role => roles.includes(role));
  }

  hasAnyRole(userRoles: Role[], requiredRoles: string[]) {
    const roles = userRoles.map(role => role.name);
    return (requiredRoles || []).some(role => roles.includes(role));
  }

  protected storeToken(response) {
    if (response && response.access_token && response.user) {
      const token = response.access_token;
      const expiresAt = response.expires_at;

      this.localStorage.setItem('token', token);
      this.localStorage.setItem('expires_at', expiresAt);
      this.authLoginAsService.logout();
      this.storeUser(response.user);
    }
  }

  storeUser(user: User) {
    this.localStorage.setItem('user', btoa(encodeURIComponent((JSON.stringify(user)))));
  }

  private storeSession(response: any) {
    this.storeToken(response);
    this.authEvents$.next(AuthEvent.LOGGED_IN);
  }

  removeToken() {
    this.localStorage.removeItem('token');
    this.localStorage.removeItem('expires_at');
    this.localStorage.removeItem('user');
    this.localStorage.removeItem('bse_hotel');
    this.localStorage.removeItem('bse_hash');
    this.dynamicLocalStorage.removeItem('isLogin');
    this.user = null;

    this.authEvents$.next(AuthEvent.LOGGED_OUT);
  }

  private unauthorized(response: any) {
    response.message = 'Nie masz wystarczających uprawnień.';
    response.status = 1;

    return response;
  }

  login(user: UserLogin) {
    return this.http.post<Response>(`${this.config.api}/auth/login`, user)
      .pipe(
        map((response: any) => {
          if (!response.user) {
            return this.unauthorized(response);
          }

          this.storeSession(response);

          return response as Response;
        })
      );
  }

  logoutLoginAs() {
    return this.http.post<Response>(`${this.config.api}/auth/logout`, null)
      .pipe(
        map((response) => {
          this.authLoginAsService.logout();
          return response;
        })
      );
  }

  logout() {
    if (this.authLoginAsService.logged) {
      return this.logoutLoginAs();
    }
    return this.http.post<Response>(`${this.config.api}/auth/logout`, null)
      .pipe(
        map((response) => {
          this.removeToken();
          return response;
        })
      );
  }

  register(user: UserRegister) {
    return this.http.post<Response>(`${this.config.api}/auth/register`, user);
  }

  requestResetPassword(email: string) {
    return this.http.post<Response>(`${this.config.api}/auth/password/email`, {email});
  }

  resetPassword(request: PasswordReset) {
    return this.http.post<Response>(`${this.config.api}/auth/password/reset`, request);
  }

  updateUser() {
    setTimeout(() => {
      this.http.get<any>(`${this.config.api}/me`)
        .subscribe(user => {
          const userModel: User = user.data;
          this.storeUser(userModel);
          this.user = userModel;
          this.appService.updateMenu(this.user.menu);
        });
    }, 250);
  }

}
