import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocalStorage {

  private isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  getItem(key: string) {
    return this.isBrowser ? localStorage.getItem(key) : undefined;
  }

  removeItem(key: string) {
    return this.isBrowser ? localStorage.removeItem(key) : undefined;
  }

  setItem(key: string, value: string) {
    return this.isBrowser ? localStorage.setItem(key, value) : undefined;
  }

}

class LocalStorageObject<T> {
  value: T;

  constructor() {
  }

  setValue(value: T) {
    this.value = value;
  }

  copy(value: {
    value
  }) {
    this.value = value.value;
  }

}

@Injectable({
  providedIn: 'root'
})
export class DynamicLocalStorage<T> {

  private isBrowser: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  getItem(key: string) {
    const value = this.isBrowser ? localStorage.getItem(key) : undefined;
    const localStorageObject = new LocalStorageObject<T>();
    localStorageObject.copy((JSON.parse(atob(value))));
    return <T> localStorageObject.value;
  }

  removeItem(key: string) {
    return this.isBrowser ? localStorage.removeItem(key) : undefined;
  }

  setItem(key: string, value: T) {
    const item = new LocalStorageObject<T>();
    item.setValue(value);
    return this.isBrowser ? localStorage.setItem(key, btoa(JSON.stringify(item))) : undefined;
  }

}

