import { Component, OnInit, Input } from '@angular/core';
import { Hotel } from '../../../shared/models/hotel';

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.scss'],
})
export class HotelComponent implements OnInit {

  @Input('hotel') hotel: Hotel;
  previewGallery = false;
  previewMap = false;

  constructor() { }

  ngOnInit() {
  }

  toggleGallery() {
    this.previewGallery = !this.previewGallery;
  }

  toggleMap() {
    this.previewMap = !this.previewMap;
  }
}
