import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {UpdateValueDialogData} from '../../models/dialogs';

@Component({
  selector: 'app-update-value-dialog',
  templateUrl: './update-value-dialog.component.html',
  styleUrls: ['./update-value-dialog.component.scss']
})
export class UpdateValueDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UpdateValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UpdateValueDialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
