import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APP_CONFIG, AppConfig} from '../../app.config';
import {RoomGroup} from '../models/room-group';
import {Observable} from 'rxjs';
import {Booking} from '../models/booking';
import {Client} from '../models/client';

interface ResponseHash {
  data: {
    hash: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class BsEngineService {
  private hash: string;
  private booking: Booking;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) protected config: AppConfig,
  ) {
    this.booking = new Booking();
  }

  set setHash(hash: string) {
    this.hash = hash;
  }

  set setRoom(room: RoomGroup) {
    this.booking.room_group = room;
  }

  get getRoom(): RoomGroup {
    return this.booking.room_group;
  }

  get getClient(): Client {
    return this.booking.client;
  }

  get getBooking(): Booking {
    return this.booking;
  }

  public getHash(): Observable<ResponseHash> {
    return this.httpClient.post<ResponseHash>(`${this.config.api}/booking-engine/get-hash`, {});
  }

  public checkRooms(): Observable<{ data: RoomGroup[] }> {
    return this.httpClient.post<{ data: RoomGroup[] }>(`${this.config.api}/booking-engine/check-rooms`, {
      date_from: this.booking.date_from,
      date_to: this.booking.date_to,
      adults: this.booking.adults,
    });
  }

  public book(): Observable<any> {
    return this.httpClient.post<any>(`${this.config.api}/booking-engine/book`, this.booking);
  }
}
