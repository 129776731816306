export class Configuration {
  id: number;
  hotel_email: string;
  payment_back_link: string;
  online_payment: boolean;
  advance_payment: boolean;
  minimum_advance_payment: number;
  time_for_complete_payment: number;
  future_reservation_restriction: number;
  cash_payment: boolean;
  primary_color: string;
  children_age: number;
  children_count: number;
  min_stay: number;
  google_analytics: string;
  google_tag_manager: string;
  url: string;
  hash: string;
  privacy_policy: string;
  packages: boolean;

  constructor() {
    this.id = this.hotel_email = this.payment_back_link = this.cash_payment = this.advance_payment
      = this.minimum_advance_payment = this.time_for_complete_payment = this.future_reservation_restriction
      = this.children_age = this.children_count = this.min_stay = this.google_analytics = this.google_tag_manager
      = this.hash = this.url = this.privacy_policy = this.packages = null;
    this.primary_color = '#2283e1';
  }
}
