import {WidgetService} from '../../services/widget.service';
import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {

  requesting = false;

  constructor(private widgetService: WidgetService) {}

  ngOnInit() {
    this.widgetService.requesting$.subscribe(requesting => this.requesting = requesting);
  }
}
