import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmDialogData } from '../../models/dialogs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) { }

  confirmed() {
    if (typeof this.data.confirmed === 'function') {
      this.data.confirmed();
    }
  }

  rejected() {
    if (typeof this.data.rejected === 'function') {
      this.data.rejected();
    }
  }

}
