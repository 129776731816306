import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Image } from '../../../shared/models/image';
import { NgxSiemaOptions, NgxSiemaService } from 'ngx-siema';

@Component({
  selector: 'app-popup-gallery',
  templateUrl: './popup-gallery.component.html',
  styleUrls: ['./popup-gallery.component.scss']
})
export class PopupGalleryComponent implements OnInit {

  @Input('images') images: Image[];
  @Output('close') close = new EventEmitter<void>();

  options: NgxSiemaOptions = {
    selector: '.bse-hotel-imgs',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: true
  };

  constructor(public ngxSiemaService: NgxSiemaService) { }

  ngOnInit() {
  }

  onClose() {
    this.close.emit();
  }

}
