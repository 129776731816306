import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { WidgetService } from '../../services/widget.service';
import { BsLocaleService } from 'ngx-bootstrap';
import { fade, toggleFade } from '../../animations';
import { Hotel } from '../../../shared/models/hotel';
import * as moment from 'moment';

@Component({
  selector: 'app-filter-offer',
  templateUrl: './filter-offer.component.html',
  styleUrls: ['./filter-offer.component.scss'],
  animations: [
    fade,
    toggleFade
  ]
})
export class FilterOfferComponent implements OnInit {

  @Output('update') change: EventEmitter<string> = new EventEmitter<string>();
  today: Date;
  reservationRestriction: Date;
  persons = false;
  _hotel: Hotel;

  constructor(
    private widgetService: WidgetService,
    private localeService: BsLocaleService
  ) { }

  ngOnInit() {
    this.localeService.use('pl');
    this.setDefaults();
  }

  setDefaults() {
    this.setFutureReservationRestriction();
    this.today = moment().toDate();
  }

  setFutureReservationRestriction() {
    this.reservationRestriction = new Date();
    this._hotel = this.widgetService.getHotel();

    if (!this._hotel) {
      this.widgetService.hotel()
        .subscribe(hotel => this.reservationRestriction.setMonth(this.reservationRestriction.getMonth() + hotel.data.configuration.future_reservation_restriction));
    } else {
      this.reservationRestriction.setMonth(this.reservationRestriction.getMonth() + this._hotel.configuration.future_reservation_restriction);
    }
  }

  onValueChange(event) {
    this.widgetService.setBookingDates(event[0], event[1]);
    this.change.emit('datesUpdate');
  }

  onChildrenChange(event) {
    this.widgetService.setChildren(event.target.value);
    this.change.emit('datesUpdate');
  }

  onAdultsChange(event) {
    this.widgetService.setAdults(event.target.value);
    this.change.emit('datesUpdate');
  }

  onRemoveAdult() {
    this.widgetService.setAdults(this.booking.adults === 1 ? 1 : --this.booking.adults);
    this.change.emit('datesUpdate');
  }

  onAddAdult() {
    this.widgetService.setAdults(++this.booking.adults);
    this.change.emit('datesUpdate');
  }

  onRemoveChild() {
    this.widgetService.setChildren(this.booking.children.count === 0 ? 0 : --this.booking.children.count);
    this.change.emit('datesUpdate');
  }

  onAddChild() {
    this.widgetService.setChildren(this.booking.children.count === this.hotel.configuration.children_count ? this.booking.children.count : ++this.booking.children.count);
    this.change.emit('datesUpdate');
  }

  onAgeChange() {
    this.widgetService.bedsChanged();
  }

  get hotel() {
    return this.widgetService.getHotel();
  }

  get booking() {
    return this.widgetService.getBooking();
  }

  get childrenCount() {
    return Array(Math.min(this.booking.children.count, this.hotel.configuration.children_count)).fill(0).map((x, i) => i);
  }
}
