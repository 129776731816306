import { BehaviorSubject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private titleSubject = new BehaviorSubject<string>('Dashboard');
  public title$ = this.titleSubject.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  setTitle(title: string) {
    this.titleSubject.next(title);
  }

  addBodyClass(className: string) {
    this.document.body.classList.add(className);
  }

  removeBodyClass(className: string[]) {
    this.document.body.classList.remove(...className);
  }
}
