import { User } from './user';
import { Address } from './address';

export class Client {
  id: number;
  user: User;
  address: Address;
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = this.created_at = this.updated_at = null;
    this.user = new User();
    this.address = new Address();
  }
}
