import {RoomGroup} from './room-group';
import {Client} from './client';
import {Package} from './package';

export class Booking {
  room_group: RoomGroup;
  package: Package;
  client: Client;
  date_from: string;
  date_to: string;
  payment_method: number;
  adults?: number;
  children: { count: number; age: number[]; };
  comments: string;
  agreements: number[];

  constructor(booking: Partial<Booking> = {}) {
    this.room_group = new RoomGroup();
    this.package = new Package();
    this.client = new Client();
    this.date_from = this.date_to = this.payment_method = this.adults = this.comments = null;
    this.agreements = [];
    this.children = {
      count: 0,
      age: []
    };

    Object.keys(booking).forEach(property => this[property] = property);
  }
}
