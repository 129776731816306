import { Agreement } from '../../../shared/models/agreement';
import { Component, Injector, ViewChild, OnInit } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget-component';
import { NgForm } from '@angular/forms';

class Price {
  online: number;
  advance: number;
  prepaid: number;

  constructor(online: number, advance: number, prepaid: number) {
    this.online = online;
    this.advance = advance;
    this.prepaid = prepaid;
  }
}

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent extends BaseWidgetComponent implements OnInit {

  @ViewChild('bookForm', { static: true }) bookForm: NgForm;
  comments = false;
  agreement = false;
  payment_method: number;
  agreements: Agreement[] = [];
  price: Price;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.agreements = this.widgetService.getHotel().agreements;

    if (this.booking.package.id) {
      const room = this.booking.package.package_room_group.find(x => x.room_group[0].id === this.booking.room_group.id);

      this.price = new Price(
        room.price * 100,
        room.advance_price * 100,
        room.price * 100
      );
    } else {
      this.price = new Price(
        this.booking.room_group.price,
        this.booking.room_group.advance_price,
        this.booking.room_group.price
      );
    }
  }

  setValidationInfo() {
    Object.keys(this.bookForm.controls).forEach(control => this.bookForm.controls[control].markAsDirty());
  }

  onPaymentChange(value) {
    this.booking.payment_method = parseInt(value, 10);
  }

  onSubmit() {
    if (this.bookForm.invalid) {
      this.setValidationInfo();

      this.messageService.info('Prosimy poprawnie wypełnić formularz.');

      return false;
    }

    this.widgetService.book()
      .subscribe((result) => {

        if (this.booking.payment_method === 100 || this.booking.payment_method === 300) {
          this.widgetService.setPaymentUrl(result.data.url);

          this.router.navigate(['/payment']);
        } else {
          this.router.navigate(['/thank-you']);
        }
      });
  }

  getFullPrice() {
    return this.price.online / 100;
  }

  getAdvancePrice() {
    return (this.price.online - this.price.advance) / 100;
  }
}
