import {Component, OnDestroy, OnInit} from '@angular/core';
import {fade, fadeIn, toggleFade} from '../../animations';
import {WidgetService} from '../../services/widget.service';
import {RoomGroup} from '../../../shared/models/room-group';
import {Hotel} from '../../../shared/models/hotel';
import {Router, ActivatedRoute} from '@angular/router';
import {merge, Subscription} from 'rxjs';
import {Package} from '../../../shared/models/package';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss'],
  animations: [
    fade,
    fadeIn,
    toggleFade
  ]
})
export class RoomsComponent implements OnInit, OnDestroy {

  constructor(
    private widgetService: WidgetService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  rooms: RoomGroup[] = [];
  hotel: Hotel;
  preview: RoomGroup;

  previewRoomMode = false;
  showRooms: any;
  private bookingChanges: Subscription;

  ngOnInit() {
    this.getHotelInfo();
    this.registerInitialBooking();
    this.getRoomGroups();
    this.registerBookingChanges();
  }

  ngOnDestroy() {
    this.bookingChanges.unsubscribe();
  }

  registerBookingChanges() {
    if (!this.bookingChanges) {
      this.bookingChanges = merge(this.widgetService.bookingDatesChange$, this.widgetService.bedsChange$)
        .subscribe(() => {
          this.getRoomGroups();
        });
    }
  }

  registerInitialBooking() {
    const checkin = this.route.snapshot.queryParamMap.get('checkin');
    const checkout = this.route.snapshot.queryParamMap.get('checkout');
    const guests = parseInt(this.route.snapshot.queryParamMap.get('guests'), 10);

    if (checkin && checkout) {
      this.widgetService.setBookingDates(checkin, checkout);
    }

    if (guests > 0) {
      this.widgetService.setAdults(guests);
    }
  }

  getRoomGroups() {
    this.widgetService.checkRooms().subscribe((response) => {
      this.rooms = response.data;
    });
  }

  getHotelInfo() {
    this.hotel = this.widgetService.getHotel();

    if (!this.hotel) {
      this.widgetService.hotel()
        .subscribe(hotel => this.hotel = hotel.data);
    }
  }

  goToConfirmation(room: RoomGroup) {
    this.widgetService.setRoom(room);
    this.widgetService.setPackages(new Package());

    this.router.navigate(['/confirmation']);
  }

  previewRoom(room: RoomGroup) {
    this.previewRoomMode = true;

    this.widgetService.previewRoom(room)
      .subscribe(result => this.preview = result.data);
  }

  onPreviewClose() {
    this.previewRoomMode = false;
    this.preview = null;
  }
}
