import { InjectionToken } from '@angular/core';

export interface AppConfig {
    production: boolean;
    api: string;
    payload_properties: string[];
    payload_issuers: string[];
    chunkUpload: string;
    gmaps_key: string;
}

export const APP_CONFIG = new InjectionToken<AppConfig>('app.config');
