import { Subject } from 'rxjs';
import { Injectable, ElementRef } from '@angular/core';

@Injectable()
export class GmapService {

  private map: google.maps.Map;
  private marker: google.maps.Marker;
  private geocoder: google.maps.Geocoder;
  private location: google.maps.LatLng;

  private markerPositionChange: Subject<any> = new Subject<any>();
  markerPosition$ = this.markerPositionChange.asObservable();

  constructor() {
    this.setUpGeocoder();
  }

  private setUpGeocoder() {
    setTimeout(() => {
      if (typeof google === 'object') {
        this.geocoder = new google.maps.Geocoder();
      } else {
        this.setUpGeocoder();
      }
    }, 250);
  }

  setLocation(position: google.maps.LatLng) {
    setTimeout(() => {
      if (typeof google === 'object') {
        this.location = position;
        this.updatePosition();
      } else {
        this.setLocation(position);
      }
    }, 250);
  }

  createMap(element: ElementRef, position?: google.maps.LatLng, zoom = 15, draggable = false) {
    if (position && position.lat && position.lng) {
      this.location = position as google.maps.LatLng;
    } else {
      if (this.geocoder) {
        this.geocoder.geocode({
          address: 'Polska'
        }, (results) => {
          if (results !== null && results.length > 0) {
            this.location = results[0].geometry.location;
          }
        });
      }
    }

    setTimeout(() => {
      if (typeof google === 'object') {
        const mapProps: google.maps.MapOptions = {
          center: this.location,
          zoom,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          disableDefaultUI: true,
        };

        this.map = new google.maps.Map(element.nativeElement, mapProps);

        this.marker = new google.maps.Marker({
          position: this.location,
          map: this.map,
          draggable,
        });

        if (draggable) {
          this.marker.addListener('dragend', (...args) => {
            if (args.length) {
              this.setLocation(args[0].latLng);
            }
          });
        }
      } else {
        this.createMap(element, position, zoom, draggable);
      }
    }, 250);
  }

  updatePosition() {
    this.markerPositionChange.next(this.location);

    this.map.setCenter(this.location);
    this.marker.setPosition(this.location);
  }

  runGeocoder(address: string) {
    setTimeout(() => {
      if (this.geocoder) {
        this.geocoder.geocode({ address }, results => {
          if (results !== null && results.length > 0) {
            this.setLocation(results[0].geometry.location);
          }
        });
      } else {
        this.runGeocoder(address);
      }
    }, 250);
  }

}
