import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import { BaseWidgetComponent } from '../base-widget-component';
import {PaymentStatus} from '../../../shared/models/payment';
import {PaymentService} from '../../services/payment.service';
import {interval, Subscription} from 'rxjs';
import {toggleFade} from '../../animations';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: [toggleFade]
})
export class PaymentComponent extends BaseWidgetComponent implements OnInit, OnDestroy {
  paymentUrl: string;
  paymentExpires: number;
  paymentStatus: PaymentStatus;
  seed: Subscription;

  constructor(
    injector: Injector,
    private paymentService: PaymentService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.paymentUrl = this.widgetService.paymentUrl();
    this.paymentExpires = this.widgetService.paymentExpires();
    this.openPayment();
    this.getPaymentStatus();
  }

  ngOnDestroy(): void {
    this.seed.unsubscribe();
  }

  openPayment() {
    window.open(this.paymentUrl, '_blank');
  }

  getPaymentStatus() {
    this.seed = interval(2000).subscribe(() => {
      this.paymentService.isPaid(this.paymentUrl).subscribe(response => {
        this.paymentStatus = response.data;

        if (response.data.status === 300) {
          this.seed.unsubscribe();
        }
      });
    });
  }
}
