import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from '../../app.config';
import {HttpClient} from '@angular/common/http';
import {Enums} from '../models/app';
import {map} from 'rxjs/operators';
import {of} from 'rxjs';

type EnumType =
  'roles'
  | 'reservations_statuses'
  | 'room_group_standards'
  | 'room_group_types'
  | 'price_tables'
  | 'advance_payments';

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  private _enums: Enums;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private http: HttpClient
  ) {
  }

  refresh() {
    return this.http.get<Enums>(`${this.config.api}/enums`)
      .pipe(
        map(enums => {
          this._enums = enums;
          return enums;
        })
      );
  }

  clearEnums() {
    this._enums = null;
  }

  roles() {
    return this._getOrReturn('roles');
  }

  reservationStatuses() {
    return this._getOrReturn('reservations_statuses');
  }

  roomGroupStandards() {
    return this._getOrReturn('room_group_standards');
  }

  roomGroupTypes() {
    return this._getOrReturn('room_group_types');
  }

  priceTables() {
    return this._getOrReturn('price_tables');
  }

  advancePayments() {
    return this._getOrReturn('advance_payments');
  }

  private _getOrReturn(type: EnumType) {
    if (!this._enums) {
      return this.refresh()
        .pipe(
          map(enums => {
            this._enums = enums;

            return enums[type];
          })
        );
    }

    return of(this._enums[type]);
  }
}
