import { APP_CONFIG, AppConfig } from '../../app.config';
import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {PaymentStatus} from '../../shared/models/payment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  isPaid(paymentUrl: string) {
    this.loadingSubject.next(true);

    return this.http.post<{ data: PaymentStatus }>(`${this.config.api}/payment/paid`, {url: paymentUrl})
      .pipe(
        catchError(() => of({ data: new PaymentStatus() })),
        finalize(() => this.loadingSubject.next(false))
      );
  }
}
