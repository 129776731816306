import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Package} from '../../../shared/models/package';
import {Hotel} from '../../../shared/models/hotel';
import {NgxSiemaOptions, NgxSiemaService} from 'ngx-siema';
import {WidgetService} from '../../services/widget.service';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit {
  @Input('package') package: Package;
  @Output('close') close = new EventEmitter<void>();

  hotel: Hotel;

  options: NgxSiemaOptions = {
    selector: '.bse-hotel-imgs',
    duration: 200,
    easing: 'ease-out',
    perPage: 1,
    startIndex: 0,
    draggable: true,
    threshold: 20,
    loop: true
  };

  constructor(
    public ngxSiemaService: NgxSiemaService,
    private widgetService: WidgetService,
  ) { }

  ngOnInit() {
    this.hotel = this.widgetService.getHotel();
  }

  onClose() {
    this.close.emit();
  }
}
