import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { InfoDialogComponent } from '../components/info-dialog/info-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import {PreviewImageComponent} from '../components/preview-image/preview-image.component';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  toast(text: string | string[], title = '') {
    setTimeout(() => {
      this.snackBar.open(Array.isArray(text) ? text.join('. ') : text, title, {
        duration: 4000,
        panelClass: 'toast'
      });
    });
  }

  info(text: string | string[], title = null, button = null, afterClosed = () => {}, icon = true) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: { text: Array.isArray(text) ? text.join('. ') : text, title, button, icon },
        minWidth: 400,
        maxWidth: 600
      });

      dialogRef.afterClosed().subscribe(() => afterClosed);
    });
  }

  confirm(text: string | string[], title = null, confirmButton = null, rejectButton = null, confirmed = () => {}, rejected = () => {}, afterClosed = () => {}) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: { text: Array.isArray(text) ? text.join('. ') : text, title, confirmButton, rejectButton, confirmed, rejected },
        minWidth: 400,
        maxWidth: 600
      });

      dialogRef.afterClosed().subscribe(() => afterClosed);
    });
  }

  imagePreview(imageUrl: string | string[]) {
    setTimeout(() => {
      const dialogRef = this.dialog.open(PreviewImageComponent, {
        data: { imageUrl: imageUrl },
        minWidth: 700,
        maxWidth: 900
      });

      dialogRef.afterClosed().subscribe();
    });
  }
}
