import { Component, OnInit, Input } from '@angular/core';
import { Hotel } from '../../../../shared/models/hotel';
import { WidgetService } from '../../../services/widget.service';

@Component({
  selector: 'app-header-minimal',
  templateUrl: './header-minimal.component.html',
  styleUrls: ['./header-minimal.component.scss']
})
export class HeaderMinimalComponent implements OnInit {

  hotel: Hotel;

  constructor(private widgetService: WidgetService) { }

  ngOnInit() {
    this.getHotel();
  }

  getHotel() {
    this.hotel = this.widgetService.getHotel();
  }

}
