import { Injectable, Injector } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WidgetService } from '../services/widget.service';

@Injectable({
  providedIn: 'root'
})
export class ValidHashGuard implements CanActivate {

  private widgetService: WidgetService;
  private router: Router;

  constructor(private injector: Injector) {
    this.widgetService = this.injector.get(WidgetService);
    this.router = this.injector.get(Router);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.widgetService.hash()) {
      this.router.navigate(['/error']);
    }

    return true;
  }
}
