import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule, MatButtonModule, MatCheckboxModule, MatInputModule, MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatChipsModule, MatIconModule, MatProgressSpinnerModule, MatDialogModule, MatSnackBarModule, MatPaginatorModule, MatSortModule, MatTableModule, MatCardModule, MAT_CHECKBOX_CLICK_ACTION, MatExpansionModule, MatGridListModule, MatAutocompleteModule, MatTabsModule } from '@angular/material';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMaskModule } from 'ngx-mask';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatExpansionModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatTooltipModule,

    NgxMaterialTimepickerModule.forRoot(),
    NgxMaskModule.forRoot(),
    DropzoneModule,
  ],
  exports: [
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatExpansionModule,
    MatGridListModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatTooltipModule,

    NgxMaterialTimepickerModule,
    NgxMaskModule,
    DropzoneModule,
  ],
  providers: [
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check' }
  ]
})
export class MaterialModule { }
