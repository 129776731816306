import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {environment, modulesToImport} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {APP_CONFIG} from './app.config';
import {HttpClientModule} from '@angular/common/http';
import {MessageService} from './shared/services/message.service';
import {MaterialModule} from './shared/modules/material.module';
import {AuthService} from './shared/services/auth.service';
import {DynamicLocalStorage, LocalStorage} from './shared/services/local-storage.service';
import {LayoutService} from './shared/services/layout.service';
import {AppService} from './shared/services/app.service';
import {MatPaginatorIntl} from '@angular/material';
import {MatPaginatorLang} from './shared/mat-paginator-lang';
import localePl from '@angular/common/locales/pl';
import {registerLocaleData} from '@angular/common';
import {BsEngineService} from './shared/services/bsEngine.service';
import * as moment from 'moment';
import {FormsModule} from '@angular/forms';
import {InfoDialogComponent} from './shared/components/info-dialog/info-dialog.component';
import {ConfirmationDialogComponent} from './shared/components/confirmation-dialog/confirmation-dialog.component';
import {UpdateValueDialogComponent} from './shared/components/update-value-dialog/update-value-dialog.component';
import {PreviewImageComponent} from './shared/components/preview-image/preview-image.component';

registerLocaleData(localePl, 'pl');
moment.locale('pl');

@NgModule({
  declarations: [
    AppComponent,
    InfoDialogComponent,
    ConfirmationDialogComponent,
    PreviewImageComponent,
    UpdateValueDialogComponent,
  ],
  entryComponents: [
    InfoDialogComponent,
    ConfirmationDialogComponent,
    UpdateValueDialogComponent,
    PreviewImageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    ...modulesToImport,
    AppRoutingModule,
    FormsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pl'},
    {provide: APP_CONFIG, useValue: environment},
    {provide: MatPaginatorIntl, useClass: MatPaginatorLang},
    MessageService,
    AuthService,
    LocalStorage,
    DynamicLocalStorage,
    LayoutService,
    AppService,
    BsEngineService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
