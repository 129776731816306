import {Component, Input, OnInit} from '@angular/core';
import {Hotel} from '../../../shared/models/hotel';

@Component({
  selector: 'app-set-styles',
  templateUrl: './set-styles.component.html',
})
export class SetStylesComponent implements OnInit {

  @Input('hotel') hotel: Hotel;

  constructor() {}

  ngOnInit() {
    document.body.style.setProperty('--main-color', this.hotel.configuration.primary_color);
  }
}
